<template>
 <CCard>
  <CCardBody>
    <div class="clearfix">
      <button type="button" class="btn btn-primary float-xl-right" @click="navToNewSymptoms">New Work Permit</button>
    </div>

    <CDataTable :items="items"  
    :fields="fields"
    
      column-filter
       table-filter
        items-per-page-select
      :items-per-page="20"
       sorter
        
      pagination>
      
       <template #index="data">
       <td>
         {{data.index +1}}
         </td>
     </template>
       <template #createdTime="{item}">
        <td class='font-weight-bold'
          v-if="item.createdTime"
        >{{ item.createdTime |dateParse()| dateFormat("MMM D, YYYY hh:mm A") }}</td>
        <td v-else>--</td>
      </template>

        <template #show_details="{item}">
        <td class="py-2">
        <CDropdown
                  togglerText="Actions"
                  color="info"
                >
                  <CDropdownItem :to="{name: 'WorkPermit', params: {permitFormId: item.permitFormId, mode: 'view'}}">View </CDropdownItem>
                   <CDropdownItem :to="{name: 'Sections', params: {permitFormId: item.permitFormId, mode: 'view'}}">Sections </CDropdownItem>
           
                <ConfirmationModal
                      :parent="$refs.modalArea"
                      :description="'Are you sure to Delete'"
                      @on:ok="deleteform(item.permitFormId)"
                    ></ConfirmationModal>
                </CDropdown>

        
        </td>
      </template>
      </CDataTable>
  </CCardBody>
   <div ref="modalArea"></div>
   <CCardFooter align="right">

            <CButton class="mr-2" type="Cancel" size="sm" color="danger"  @click="cancel()">
              <CIcon name="cil-ban" /> Cancel
            </CButton>
          </CCardFooter>
 </CCard>

</template>

<script>

const fields = [
  { key: "index", _classes: 'font-weight-bold', _style: "min-width:200px" },
  { key: "title", _classes: 'font-weight-bold',  _style: "min-width:200px" },
  { key: "docNo", label: "Doc No",  _classes: 'font-weight-bold',_style: "min-width:200px" },
 { key: "divisionNumber",label: "Revision Number", _classes: 'font-weight-bold', _style: "min-width:200px" },
//  { key: "status",  _style: "min-width:200px" },
  
  { key: "createdTime", label: "Created Date & Time", _classes: 'font-weight-bold',_style: "min-width:200px" },
  { key: "show_details",
    label: "",
    _style: "width:1%",
    sorter: false,
    filter: false,
  },
];
import ConfirmationModal from "../../containers/ConfirmationModal";
export default {
  name: "Work Permits",
  components: {
     ConfirmationModal,
   },
  data() {
    return {
      items: [],
      fields,
      details: [],
      collapseDuration: 0,
    };
  },
  methods: {
    getWorkPermit() {
       this.apiGetSecure(process.env.VUE_APP_API_HOST + "/emapp/web/secure/workPermit/forms/"+this.$route.params.groupId)
        .then((response) => response.json())
        .then((data) => (this.items = data));
    },
      deleteform(permitFormId) {
      console.log("delete called with", permitFormId);
      this.apiGetSecure(
        process.env.VUE_APP_API_HOST + "/emapp/web/secure/delete/workPermit/forms/" + permitFormId)
        .then((response) => response.json())
        .then((data) => {
          this.getWorkPermit();
             this.$nextTick(() => {
             this.$refs.vuetable.refresh();
           });
         window.location.reload();
        });
    },
    cancel() {
   
             this.$router.push({ name: "Groups"});
    },
    navToNewSymptoms(){
       this.$router.push({ name: "NewWorkPermit", params: { groupId:this.$route.params.groupId,mode: 'new' } });
    }
  },
  mounted() {
    this.getWorkPermit();
  },
};
</script>